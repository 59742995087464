@tailwind base;
@tailwind components;
@tailwind utilities;


/* Fuentes  */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
.robotoC {
  font-family: 'Roboto Condensed', 'azonix';
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
.inter {
  font-family: 'Inter', sans-serif;
}
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
.outfit {
  font-family: 'Outfit', sans-serif;
}
@font-face {
  font-family: 'Azonix';
  src: url('/src/assets/fonts/Azonix.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
.azonix {
  font-family: 'Azonix', sans-serif;
}
@font-face {
  font-family: 'Satisfy';
  src: url('/src/assets/fonts/Satisfy.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
.satisfy {
  font-family: 'Satisfy', sans-serif;
}




/* Colores  */
  /* Colores de Fondo  */
.colorBgBlue {
  background-color: #111827;
}
.colorBgRed {
  background-color: #e10f1a;
}
.colorBgWhite {
  background-color: #f1f1f1;
}
  /* Colores de Letras  */
.colorFontBlue {
  color: #111827;
}
.colorFontRed {
  color: #e10f1a;
}
.colorFontWhite {
  color: #f1f1f1;
}



/* Otros  */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}